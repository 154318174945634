import React, { useContext } from "react"
import BannerContext from "../lib/context"

import Helmet from "react-helmet"

import Layout from '../components/layout'

import Header from "../components/Header"
import { useLocalData } from "../lib/hooks"
import Section, { TextSection, BackgroundSection, HalfCardSection, HalfBackgroundSection } from '../components/Section'
import { getStaticPageData } from "../lib/API"
import BannerSection from "../components/BannerSection"


// import YoungAdultImage from '../assets/images/home/young-adults.jpg'


import YoungAdultImage from '../assets/images/groups/Youth-Groups.jpg'
import VerticalYouth from '../assets/images/church/vertical-youth-group.jpg'
import ActivitiesImage from '../assets/images/home/activities.jpg'
import Youth from '../assets/images/stock-photos/youth2.jpg'
import YouthMinistry from '../assets/images/stock-photos/youthministry1.jpg'


import YouthBand from '../assets/images/stock-photos/youth-band.png'
import PrayerMeetingImage from '../assets/images/home/life-group.png'
import ChildrenImage from '../assets/images/home/children.jpg'
import Fireplace from '../assets/images/stock-photos/fireplace.jpg'
import Emerge from '../assets/images/stock-photos/emerge.jpg'

import Globe from '../assets/images/core/mission.png'
import ElderlyImage from '../assets/images/home/cafe.jpg'
import LunchImage from '../assets/images/home/monday-lunch.png'
// import PrayerMeetingI from '../assets/images/home/monday-lunch.png'

// import LifeImage from '../assets/images/home/life-groups2.jpg'
import LifeImage from '../assets/images/groups/Homegroups.jpg'





const Homepage = () => {

    // const [ headerData, setHeaderData ] = useState( [ ] )
    const siteTitle = "Blackheath and Charlton Baptist Church - Youth"
    // const pageData = useLocalData( getStaticPageData, 'church-life-banners', 'church-life-banners' )
    const pageData = useContext( BannerContext )[ 'youth-banners' ]
    const { header, banners } = pageData || { header: null, banners: null }
    const headerImages = header ? header.images : [Youth]
    const headerTitle = header ? header.title : 'Youth'
    // const headerImages = useData( getEventsHeader )
    // const headerTitle = "Church Life"
    const headerIcon = <span className="icon major"><i className="fas fa-heartbeat"></i></span>

    return (
        
        <div id='youth-page'>
            <Header images={headerImages} headerTitle={headerTitle} tint={0.25} />
            <Helmet title={siteTitle} />


            <BackgroundSection 
                id="childrens-church" 
                title="Children's Church: B.L.A.S.T - (Being Light and Salt)"
                description={`Our Children’s Church ministry provides children the opportunity to learn about Christ and scripture, through practical and interactive activities and conversations.
                        
                    We believe that creative learning enhances their ability to learn about God and engage with His Word.
                        
                        Throughout the year we offer a variety of activities that allow children to connect with the wider church family through specific areas of interest to them. 
                        
                        Our children’s ministry leaders have all undergone safeguarding training and are committed to prioritising the children’s safety and welfare.`}
                style="style2"
                // buttons={[ { url: "/childrens-church", text: "Read More" } ]}
                background={[ChildrenImage]}
                alt
                tint={0.4}
                blur={.05}
            />
{/* 
            <div className="grid-wrapper" style={{width: '100%', height: '100%', gridGap: '0 0'}}>
                <HalfCardSection
                    title='Creche & Sunday School'
                    image={ChildrenImage}
                >
                    <p></p><br/>
                    <p>Our Children’s Church ministry offers children in our church family the opportunity to learn about Christ and scriptures, through practical and interactive engagements and conversations.</p> 
                <p>We believe that creative learning using a variety of resources that are relevant to our children’s daily lives enhances their ability to learn about God and engage with scripture.</p>
                <p>We have a number of activities that also take place during the year, which enable our children to engage with the wider church family using specific areas of interest
                 to them. Our children’s ministry leaders have all undergone safeguarding training and endeavour to the best of their ability 
                 to always put the children’s safety and welfare first.</p>
                </HalfCardSection>

                <HalfCardSection
                    title='Youth Worship Band'
                    image={YouthBand}
                    left={false}
                >
                    <p>(Ages xxx - xxx) </p><br/>
                    <h4><b></b></h4>
                    <p>Youth worship band description</p>
                </HalfCardSection> */}

                <HalfBackgroundSection
                    title='Youth Ministry (Ages 13-17)'
                    background={[YouthMinistry]}
                    description={`Our Youth Ministry offers a dynamic space for teens to grow in faith and fellowship through dedicated youth services and activities.`}
                    tint={0.3}
                />

                 <TextSection 
                    id="vertical-youth-club" 
                    title="Vertical Youth Club"
                    description={`Every Tuesday during term time, from 6:00 pm to 7:00 pm
                        children and youth gather to play games, socialise, and build lasting friendships.`}
                    style="style2"
                    // buttons={[ { url: "/childrens-church", text: "Read More" } ]}
                    background={[VerticalYouth]}
                    tint={0.4}
                    blur={0.3}
                />

                <HalfBackgroundSection
                    title='Youth Worship Band'
                    background={[YouthBand]}
                    description={`Our young musicians meet twice a month to practice various instruments. 
                        They then join other local churches every six weeks to play live, developing their musical talents and worship skills. `}
                    tint={0.3}

                    alt
                />

                {/* <HalfCardSection
                    title='Vertical Youth Club'
                    image={ActivitiesImage}
                    left={false}
                >
                    <p>(Ages 13 - 17) </p><br/>
                    <h4><b></b></h4>
                    <p>Vertical Youth Club description</p>
                </HalfCardSection>
            </div> */}

        

            <BannerSection banners={banners} />


        </div>
    )
}

export default Homepage